import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import InnerHeader from "../components/molecules/InnerHeader"
import ContactSection from "../components/common/ContactSection"
import FaqsSection from "../components/common/FaqsSection"
import OurPropertiesSection from "../components/pages/Properties/OurPropertiesSection"
import { faqs, propertiesPage } from "../content/PropertiesPage"
import DropdownFilter from "../components/atoms/DropdownFilter"
import DropdownNumberFilter from "../components/atoms/DropdownNumberFilter"
import { properties } from "../content/Properties"

const Properties = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [filters, setFilters] = useState({
    region: [],
    propertyStatus: [],
    category: [],
    beds: "",
    baths: "",
  })

  const regions = [...new Set(properties.map(post => post.region))]
  const propertyStatus = [
    ...new Set(properties.map(post => post.propertyStatus)),
  ]
  const categories = [...new Set(properties.map(post => post.category))]
  const bedBathOptions = [
    { title: "Beds", items: ["Any", "1+", "2+", "3+", "4+"] },
    { title: "Baths", items: ["Any", "1+", "2+", "3+", "4+"] },
  ]

  const handleFilterChange = (field, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value,
    }))
  }

  const clearFilters = () => {
    setFilters({
      region: [],
      propertyStatus: [],
      category: [],
      beds: "",
      baths: "",
    })
  }
  useEffect(() => {
    console.log("Updated filters:", filters)
  }, [filters])
  return (
    <Layout>
      <Seo
        title="Specialist Disability Accommodation (SDA) – Safe, Comfortable, and Supportive Living"
        description="Explore a variety of high-quality, purpose-built SDA properties. Our accessible accommodations combine comfort, independence, and assistance in an environment of security and comfort."
      />

      <InnerHeader
        title={propertiesPage.title}
        description={propertiesPage.description}
        googleReviewText={propertiesPage.googleReviewText}
        isSearchBox={true}
        formDetails={propertiesPage.formDetails}
        setSearchQuery={setSearchQuery}
      />

      <div className="cc-section--horizontal-space flex flex-wrap gap-[10px] items-center mt-[60px] lg:mt-[-70px]">
        <DropdownFilter
          data={{
            postFilterText: "region",
            dropdownBtnText: "Regions",
            dropdownItems: regions,
          }}
          selectedItems={filters.region}
          onSelect={handleFilterChange}
        />
        <DropdownFilter
          data={{
            postFilterText: "propertyStatus",
            dropdownBtnText: "Property Status",
            dropdownItems: propertyStatus,
          }}
          selectedItems={filters.propertyStatus}
          onSelect={handleFilterChange}
        />
        <DropdownFilter
          data={{
            postFilterText: "category",
            dropdownBtnText: "Property Type",
            dropdownItems: categories,
          }}
          selectedItems={filters.category}
          onSelect={handleFilterChange}
        />
        <DropdownNumberFilter
          label="Beds & Baths"
          subheadings={bedBathOptions}
          selectedItems={filters}
          onSelect={handleFilterChange}
        />
        <div
          onClick={clearFilters}
          className="bg-secondary relative bottom-[3px] min-w-[85px] shadow rounded-[8px] text-white text-[14px] leading-[1.2em] font-[600] text-center px-[20px] py-[16px] cursor-pointer transition duration-300 hover:bg-[#1C5C4F99]"
        >
          <span>Clear</span>
        </div>
      </div>

      <OurPropertiesSection searchQuery={searchQuery} filters={filters} />
      <FaqsSection faqs={faqs} />
      <ContactSection data={contactSection} sectionTitleAlignment="center" />
    </Layout>
  )
}

export default Properties
