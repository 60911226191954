import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const DropdownNumberFilter = ({
  label,
  subheadings,
  selectedItems,
  onSelect,
}) => {
  const handleItemClick = (subheadingTitle, item) => {
    // Toggle item selection: if already selected, remove it, else add it
    onSelect(subheadingTitle, item)
    console.log(selectedItems["beds"])
  }

  const isItemSelected = (subheadingTitle, item) => {
    return selectedItems[subheadingTitle]?.includes(item)
  }

  return (
    <div className="group pb-[5px] relative max-w-[max-content]">
      <div
        className={`bg-primary flex items-center justify-center gap-[5px] shadow rounded-[8px] text-[#25282C] text-[14px] leading-[1.2em] font-[600] text-center px-[20px] py-[16px] transition duration-300 hover:bg-[#1C5C4F] hover:text-white ${
          selectedItems["beds"] !== "" || selectedItems["baths"] !== ""
            ? "!bg-[#1C5C4F] text-white"
            : ""
        }`}
      >
        <span>{label}</span>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`text-secondary group-hover:text-white  ${
            selectedItems["beds"] !== "" || selectedItems["baths"] !== ""
              ? "!text-white"
              : ""
          }`}
        />
      </div>
      <div className="hidden absolute z-[9] top-[52px] left-[0] w-[220px] bg-white rounded-[8px] shadow p-[15px] group-hover:block">
        {subheadings.map((subheading, index) => (
          <div key={index} className="mb-3">
            <h5 className="text-[#1C5C4F] font-[500]">{subheading.title}</h5>
            <table className="border-collapse w-full">
              <tbody>
                <tr>
                  {subheading.items.map((item, idx) => (
                    <td
                      key={idx}
                      onClick={() =>
                        handleItemClick(subheading.title.toLowerCase(), item)
                      }
                      className={`border border-[#1C5C4F] text-[#5a5a5a] px-[3px] text-center cursor-pointer transition duration-300 hover:bg-[#1C5C4F] hover:text-white ${
                        isItemSelected(subheading.title.toLowerCase(), item)
                          ? "bg-[#1C5C4F] !text-white"
                          : ""
                      }`}
                    >
                      {item}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DropdownNumberFilter
