import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const DropdownFilter = ({ data, onSelect, selectedItems }) => {
  const handleCheckboxChange = item => {
    const newSelectedItems = selectedItems.includes(item)
      ? selectedItems.filter(i => i !== item)
      : [...selectedItems, item]

    // Call onSelect to update the parent component (Properties) with new selected items
    onSelect(data.postFilterText, newSelectedItems)
  }
  return (
    <div className="group pb-[5px] relative max-w-[max-content]">
      <div className={`bg-primary flex items-center justify-center gap-[5px] shadow rounded-[8px] text-[#25282C] text-[14px] leading-[1.2em] font-[600] text-center px-[20px] py-[16px] transition duration-300 hover:bg-[#1C5C4F] hover:text-white ${
        selectedItems.length > 0 ? "!bg-[#1C5C4F] text-white" : ""
      }`}>
        <span>{data.dropdownBtnText}</span>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`text-secondary group-hover:text-white  ${
            selectedItems.length > 0 ? "!text-white" : ""
          }`}
        />
      </div>
      <ul className="hidden absolute z-[9] top-[52px] left-[0] w-[max-content] min-w-[150px] py-2 bg-white rounded-[8px] shadow group-hover:block">
        {data.dropdownItems.map((item, index) => (
          <li
            key={index}
            className="text-[14px] px-3 py-1 text-[#25282C]"
          >
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedItems.includes(item)}
                onChange={() => handleCheckboxChange(item)}
                className="mr-2"
              />
              {item}
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DropdownFilter
